import {
  LotteryAnimation,
  ContentPageLose,
  ContentPageReceive,
} from '@components';
import { campaignService } from '@services';
import { mapState, mapGetters } from 'vuex';

import {
  WIN_FLAG,
  LOSE_FLAG,
  DELAY_LOTTERY_GAME,
  NOT_DELAY_LOTTERY_GAME,
  HTTP_CODE_OK,
  HTTP_CODE_GET_SURVEY_LINK_FAILED,
  HTTP_CODE_LOTTERY_UUID_NOT_EXIST,
  HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG,
  FLOW_TYPE_REDIRECT,
  ANIMATION_TYPE_NONE,
  ANIMATION_TYPE_DEFAULT,
  ANIMATION_TYPE_CUSTOMIZE
} from '@constants';

export default {
  name: 'LotteryPlay',
  computed: {
    ...mapState({
      campaignSetting: 'campaignSetting',
      receiveContentSetting: 'receiveContentSetting'
    }),
    ...mapGetters({
      lotteryCampaign: 'lotteryCampaign',
      campaignInfo: 'campaignInfo',
    })
  },
  components: {
    LotteryAnimation,
    ContentPageLose,
    ContentPageReceive
  },
  data() {
    return {
      winFlag: null,
      itemBrandName: '',
      itemName: '',
      winItemImageUrl: '',
      winItemMainText: '',
      winItemSubText: '',
      winReceiveItemUrl: '',
      constWinFlag: WIN_FLAG,
      constLoseFlag: LOSE_FLAG,
      isCallingReceiveGift: false,
      hasResultLottery: false,
      isAccessUniqueUrl: false,
    };
  },
  methods: {
    lotteryInitial: async function () {
      // Check lottery campaign have win flag is lose flag
      if (
        this.lotteryCampaign.winFlg == LOSE_FLAG
      ) {
        this.hasResultLottery = true;
        this.winFlag = LOSE_FLAG;
        // Stop page redirect loading
        this.$store.dispatch('stopPageRedirectLoading');
      } else {
        await campaignService
          .lotteryPlay({
            lottery_uuid: this.$route.params.lotteryUuid,
            campaign_url_name: this.$route.params.campaignUrlName,
          })
          .then(async (res) => {
            const data = res.data;
            this.hasResultLottery = true;

            // Set campaign url and campaign url name
            this.$store.dispatch('setCampaignUrlData', {
              campaignUrl: data.campaign_url,
              campaignUrlName: data.campaign_url_name,
            });

            if (data && res.status === HTTP_CODE_OK) {
              // Check campaign flow redirect when lose
              if (
                data.flow === FLOW_TYPE_REDIRECT &&
                data.win_flag === LOSE_FLAG
              ) {
                // Call handle lottery play
                this.lotteryPlay(data, NOT_DELAY_LOTTERY_GAME);
              } else {
                // Check if lottery is WIN and skip receive but cannot generate gift url
                if (this.campaignSetting?.skip_receive_flg &&
                  !data.campaign_gift?.gift_url &&
                  data.win_flag === WIN_FLAG
                ) {
                  // If skip receive page but not have receive item url will redirect to error page
                  // Set delay DELAY_LOTTERY_GAME for lottery animation play
                  setTimeout(() => {
                    return this.$router.push({
                      name: 'campaign.error',
                      replace: true,
                    });
                  }, DELAY_LOTTERY_GAME);
                } else {
                  // Check case skip receive page and lottery lose will stop loading
                  if (this.campaignSetting?.skip_receive_flg && data.win_flag === LOSE_FLAG) {
                    // Stop page redirect loading
                    this.$store.dispatch('stopPageRedirectLoading');
                  }

                  // Lottery animation type NONE will not delay 
                  const delay = this.campaignSetting?.lottery_animation_type == ANIMATION_TYPE_NONE ? NOT_DELAY_LOTTERY_GAME : DELAY_LOTTERY_GAME;

                  // Call handle lottery play
                  this.lotteryPlay(data, delay);
                }
              }
            } else if (
              data.status === HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG ||
              data.status === HTTP_CODE_GET_SURVEY_LINK_FAILED ||
              data.status === HTTP_CODE_LOTTERY_UUID_NOT_EXIST
            ) {
              this.$router.push({
                name: 'campaign.error.404',
                replace: true,
              });
            } else {
              this.$router.push(
                this.$helpers.redirectWithUtmQuery(
                  'campaign.home',
                  {},
                  {
                    campaignUrlName: data.campaign_url_name,
                    campaignUrl: data.campaign_url,
                  },
                  true,
                ));
            }
          }
        );
      }
    },
    calcLotteryResult(data) {
      if (data.win_flag === WIN_FLAG) {
        this.itemBrandName = data.campaign_gift.item_brand_name;
        this.itemName = data.campaign_gift.item_name;
        this.winItemImageUrl = data.campaign_gift.win_item_image_url;
        this.winItemMainText = data.campaign_gift.win_item_title || this.receiveContentSetting?.main_text || '';
        this.winItemSubText = data.campaign_gift.win_item_content || this.receiveContentSetting?.sub_text || '';
        this.winReceiveItemUrl = data.campaign_gift.gift_url;

        // Check if have setting skip receive and have receive item url will handle
        if (this.campaignSetting?.skip_receive_flg && this.winReceiveItemUrl) {
          // If have receive item will redirect
          return window.location.href = this.winReceiveItemUrl;
        } else {
          // Set winFlag to win to show screen gift
          this.winFlag = WIN_FLAG;
          // Stop page redirect loading
          this.$store.dispatch('stopPageRedirectLoading');
        }
      } else if (data.win_flag === LOSE_FLAG) {
        this.winFlag = LOSE_FLAG;
        // Stop page redirect loading
        this.$store.dispatch('stopPageRedirectLoading');
      }
    },
    lotteryPlay(data, delay = 0) {
      if (delay === DELAY_LOTTERY_GAME) {
        setTimeout(() => this.calcLotteryResult(data), DELAY_LOTTERY_GAME);
      } else {
        this.calcLotteryResult(data);
      }
    },
    receiveGift: async function () {
      this.isCallingReceiveGift = true;
      if (this.winReceiveItemUrl) {
        return window.location.href = this.winReceiveItemUrl;
      } else {
        await campaignService
          .createGiftUrl({
            lottery_uuid: this.$route.params.lotteryUuid,
            campaign_url_name: this.$route.params.campaignUrlName,
          })
          .then((res) => {
            if (res.status === HTTP_CODE_OK) {
              window.location.href = res.data.gift_url;
            } else {
              this.$router.push({
                name: 'campaign.error',
                replace: true,
              });
            }
          });
      }
      this.isCallingReceiveGift = false;
    },
    // Check flg is lottery play
    isLotteryFlg() {
      return this.winFlag === null && this.hasResultLottery;
    },
    /*
      Check condition start page redirect loading
      Condition: 
      When campaign is skip receive or entry type is redirect
    */
    isStartPageRedirectLoading() {
      return this.campaignInfo?.campaign_flow == FLOW_TYPE_REDIRECT ||
        (this.campaignSetting?.lottery_animation_type == ANIMATION_TYPE_NONE && !this.hasResultLottery);
    }
  },
  watch: {
    // If win flag is win or lose, set app container justify content to start
    winFlag(value) {
      if (value) {
        this.$helpers.setRootElementStyle('--app-justify-content', 'start');
      }
    },
  },
  mounted() {
    // Call Lottery initial
    this.lotteryInitial();
  },
  created() {
    /*
      Check if have setting skip receive or entry type redirect
      Will keep loading page until finish
    */
    if (this.isStartPageRedirectLoading()) {
      // Start page loading
      this.$store.dispatch('startPageRedirectLoading');
    }

    // Check if is access by unique URL will stop page loading
    if (this.$route.params?.isAccessUniqueUrl &&
      ([
        ANIMATION_TYPE_DEFAULT,
        ANIMATION_TYPE_CUSTOMIZE
      ].includes(this.campaignSetting?.lottery_animation_type))) {
      // Assign variable isAccessUniqueUrl value
      this.isAccessUniqueUrl = true;
      // Stop page redirect loading
      this.$store.dispatch('stopPageRedirectLoading');
    }

    // If win flag is lottery status, set app container justify content to center
    if (!this.winFlag) {
      this.$helpers.setRootElementStyle('--app-justify-content', 'center');
    }
  }
};
