<template>
  <div>
    <ScreenLoading v-if="isPageLoading" transition="fade" />
    <div v-if="showNextPage()" class="app-container">
      <!-- Header -->
      <Header />

      <!-- Body + Footer -->
      <div class="main-container">
        <router-view
          class="body-container"
        />

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { 
  ScreenLoading,
  Header,
  Footer
} from '@components';
import { 
  BODY_BACKGROUND_TYPE_FILL,
  BODY_BACKGROUND_TYPE_GRADIENT,
  BODY_BACKGROUND_TYPE_IMAGE,
  BUTTON_BACKGROUND_TYPE_FILL,
  BUTTON_BACKGROUND_TYPE_GRADIENT,
  ROUTER_NAME_CAMPAIGN_HOME,
  FLOW_TYPE_REDIRECT,
  CAMPAIGN_SERIAL_TYPE
} from '@constants';

export default {
  name: 'GifteeCPLayout',
  components: {
    ScreenLoading,
    Header,
    Footer,
  },
  methods: {
    applyStyles({
        headerBgColor,
        headerBgOpacity,
        keyColor,
        bodyBgType,
        bodyBg,
        contentBgColor,
        contentBgOpacity,
        contentTextColor,
        textColor,
        buttonBgType,
        buttonBgColor,
        buttonTextColor,
    }) {
      // Assuming the API response is an object with CSS properties
      const root = document.documentElement;

      // Convert header background color with opacity
      if (headerBgColor && headerBgOpacity) {
        headerBgColor = this.$helpers.hexToRgba(headerBgColor, headerBgOpacity);
      }

      // Convert content background color with opacity
      if (contentBgColor && contentBgOpacity) {
        contentBgColor = this.$helpers.hexToRgba(contentBgColor, contentBgOpacity);
      }

      // Set body background
      let bodyBgStyle = bodyBg;
      if (bodyBgType && bodyBg) {
        switch (bodyBgType) {
          case BODY_BACKGROUND_TYPE_GRADIENT:
            bodyBgStyle = `linear-gradient(to bottom, ${bodyBg})`;
            break;
          case BODY_BACKGROUND_TYPE_FILL:
            bodyBgStyle = bodyBg;
            break;
          case BODY_BACKGROUND_TYPE_IMAGE:
            bodyBgStyle = `url(${bodyBg}) center center fixed`;
            break;
        }
      }

      // Apply appearance settings
      // -- Special body background
      if (bodyBgType == BODY_BACKGROUND_TYPE_IMAGE) {
        // Set none color for header
        root.style.setProperty('--app-header-background-color', 'transparent');
        // Set none color for body + footer
        root.style.setProperty('--app-body-background', 'none');
        // Set background image + style for all app
        root.style.setProperty('--app-main-container-background', bodyBgStyle);
        root.style.setProperty('--app-main-container-background-size', 'cover');
        root.style.setProperty('--app-main-container-background-repeat', 'no-repeat');
      } else {
        // Reset background image + style for all app
        root.style.setProperty('--app-main-container-background', 'none');
        root.style.setProperty('--app-main-container-background-size', 'unset');
        root.style.setProperty('--app-main-container-background-repeat', 'unset');
        // Set background color for header
        root.style.setProperty('--app-header-background-color', headerBgColor);
        // Set background color for body + footer
        root.style.setProperty('--app-body-background', bodyBgStyle);
      }
      // -- Body
      root.style.setProperty('--app-body-key-color', keyColor);
      root.style.setProperty('--app-body-content-background-color', contentBgColor);
      root.style.setProperty('--app-body-content-text-color', contentTextColor);
      root.style.setProperty('--app-body-text-color', textColor);

      // -- Button background color
      let buttonBgStyle = buttonBgColor;
      if (buttonBgType && buttonBgColor) {
        switch (buttonBgType) {
          case BUTTON_BACKGROUND_TYPE_GRADIENT:
            buttonBgStyle = `linear-gradient(to bottom, ${buttonBgColor})`;
            break;
          case BUTTON_BACKGROUND_TYPE_FILL:
            buttonBgStyle = buttonBgColor;
            break;
        }
      }
      root.style.setProperty('--app-button-background-color', buttonBgStyle);
      root.style.setProperty('--app-button-text-color', buttonTextColor);

      // Calculate and set the height of part's screen
      root.style.setProperty('--window-vh', window.innerHeight + 'px');
      root.style.setProperty('--header-vh', '50px');
      root.style.setProperty('--footer-vh', '80px');
      
      // Apply flex content center
      root.style.setProperty('--app-justify-content', 'start');

      // Common
      root.style.setProperty('--app-loading-spinner-background-color', this.$helpers.hexToRgba(buttonTextColor, 50));
    },
    isEntryTypeRedirect() {
      return this.campaignInfo?.campaign_flow == FLOW_TYPE_REDIRECT;
    },
    skipStopPageLoading() {
      return (this.campaignSetting?.skip_top_flg && this.$route.name == ROUTER_NAME_CAMPAIGN_HOME) || this.isEntryTypeRedirect();
    },
    showNextPage() {
      return this.campaignSetting && (!this.isPageLoading || this.campaignSetting?.skip_top_flg || this.isEntryTypeRedirect());
    },
    isAccessUniqueUrl() {
      return this.$route.query?.code && this.campaignInfo?.campaign_type == CAMPAIGN_SERIAL_TYPE;
    },
  },
  computed: {
    ...mapState({
      campaignSetting: 'campaignSetting',
      isPageLoading: 'isPageLoading',
      campaignInfo: 'campaignInfo'
    })
  },
  mounted() {
    // Check if skip top page or entry tool is redirect will keep loading page to handle
    if (!this.skipStopPageLoading()) {
      // Set time out
      setTimeout(() => {
        // Stop page loading
        this.$store.dispatch('stopPageLoading');
      }, 1000) // Show loading screen for 1 sec
    }
  },
  created() {
    if (this.isEntryTypeRedirect() || this.isAccessUniqueUrl()) {
      this.$store.dispatch('startPageRedirectLoading');
    } else {
      this.$store.dispatch('startPageLoading');
    }

    // Apply the styles for the campaign
    this.applyStyles({
      headerBgColor: this.campaignSetting.header_background_color,
      headerBgOpacity: this.campaignSetting.header_background_opacity,
      keyColor: this.campaignSetting.body_key_color,
      bodyBgType: this.campaignSetting.body_background_type,
      bodyBg: this.campaignSetting.body_background_data,
      contentBgColor: this.campaignSetting.body_content_background_color,
      contentBgOpacity: this.campaignSetting.body_content_background_opacity,
      contentTextColor: this.campaignSetting.body_content_text_color,
      textColor: this.campaignSetting.body_text_color,
      buttonBgType: this.campaignSetting.button_background_type,
      buttonBgColor: this.campaignSetting.button_background_color,
      buttonTextColor: this.campaignSetting.button_text_color,
    });

    // Set the page title
    if (this.campaignInfo?.campaign_title) {
      document.title = this.campaignInfo.campaign_title;
    }

    // Set the favicon
    if (this.campaignInfo?.campaign_favicon_image_url) {
      const linkFavicon = document.querySelector("link[rel~='icon']");
      linkFavicon.href = this.campaignInfo.campaign_favicon_image_url;
    }
  },
}
</script>

<style lang="scss" scoped></style>
