import { mapState } from 'vuex';
import { campaignService } from '@services';
import {
  MainVisualContent,
  AgreementContent,
  DetailContent,
  AccordionContent,
  EntrySerialCode,
} from '@components';
import {
  HTTP_CODE_OK,
  API_STATUS_CODE_IS_EXPIRED,
  API_STATUS_CODE_RATE_LIMIT_EXCEED,
  WIN_FLAG,
  LOSE_FLAG,
  IS_SKIP_AGREEMENT_FLG,
  SURVEY_TOOL_SURVEY_MONKEY,
  SURVEY_TOOL_GOOGLE_FORM,
  SERIAL_CODE_DEFAULT
} from '@constants';

export default {
  name: 'EntrySerialCodeAuth',
  components: {
    MainVisualContent,
    AgreementContent,
    DetailContent,
    AccordionContent,
    EntrySerialCode,
  },
  data() {
    return {
      openAccordionState: 0,
      hasInputSerialCode: false,
      inputSerialCode: '',
      serialCodeInvalid: false,
      messageErrors: '',
      isProcessing: false,
      isAccessUniqueUrl: false,
    };
  },
  computed: {
    ...mapState({
      entryContentSetting: 'entryContentSetting',
      topContentSetting: 'topContentSetting',
      campaignInfo: 'campaignInfo',
      campaignSetting: 'campaignSetting',
      campaignUrl: 'campaignUrl',
      campaignUrlName: 'campaignUrlName',
      utmCampaign: 'utmCampaign',
      utmMedium: 'utmMedium',
      utmSource: 'utmSource',
    }),
  },
  methods: {
    // Get header logo url
    fullMainVisualUrl() {
      let mainVisualImageUrl = this.entryContentSetting.main_visual_image_url;
      return mainVisualImageUrl ? mainVisualImageUrl : '';
    },
    // Set Open/Close state a collapse in a accordion
    openAccordion(value) {
      // Update open state of accordion
      this.openAccordionState = value;
    },
    // Set input serial code
    setInputSerialCode(value) {
      // Set input serial code value
      this.inputSerialCode = value;
      // Reset input serial code state
      this.serialCodeInvalid = false;

      if (value) {
        this.hasInputSerialCode = true;
      } else {
        this.hasInputSerialCode = false;
      }
    },
    // Validate before submit
    validateBeforeSubmit() {
      if (this.inputSerialCode.match(/^\S+$/)) {
        return true;
      } else {
        if (this.$refs.entrySerialCode) {
          this.$refs.entrySerialCode.$refs.inputSerialCode.focus();
        }

        this.serialCodeInvalid = true;
        this.messageErrors = this.$t('SERIAL_CODE_PAGE.ERROR_MSG.SERIAL_CODE_WRONG_FORMAT');

        return false;
      }
    },
    // Handle lottery play redemption for specify campaign
    async lotteryPlayRedemption(serialCode) {
      this.isProcessing = true;

      await campaignService
        .lotteryPlayRedemption({
          campaign_url: this.campaignUrl,
          campaign_url_name: this.campaignUrlName,
          serial_code: serialCode,
          utm_campaign: this.utmCampaign,
          utm_medium: this.utmMedium,
          utm_source: this.utmSource,
        })
        .then((res) => {
          if (res.data?.win_flag === WIN_FLAG) {
            if (res.data?.campaign_gift?.gift_url) {
              window.location.href = res.data.campaign_gift.gift_url;
            } else {
              return this.$router.push({
                name: 'campaign.error',
              });
            }
          } else if (res.data?.win_flag === LOSE_FLAG) {
            // Redirect to lost lottery game
            this.$store.dispatch('setLotteryCampaignWinFlg', res.data.win_flag);

            return this.$router.push({
              name: 'lottery.game',
              params: {
                campaignUrlName: this.campaignUrlName,
                lotteryUuid: res.data?.lottery_url,
              },
            }).catch(() => {});
          } else {
            (this.serialCodeInvalid = true),
              (this.messageErrors = this.$t('SERIAL_CODE_PAGE.ERROR_MSG.INVALID_SERIAL_CODE'));
          }
        });

      this.isProcessing = false;
    },
    // Handle submit join campaign
    async handleJoinCampaign() {
      if (this.validateBeforeSubmit()) {
        this.isProcessing = true;
        const serialCode = this.inputSerialCode.toUpperCase();

        // Case campaign is redemption campaign
        if (this.campaignInfo?.is_redemption_campaign) {
          return await this.lotteryPlayRedemption(serialCode);
        }

        await campaignService
          .auth('serial-auth', {
            campaign_url: this.campaignUrl,
            campaign_url_name: this.campaignUrlName,
            serial_code: serialCode,
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource,
          })
          .then((res) => {
            const data = res.data;

            if (
              data?.lottery_url !== undefined &&
              res.status === HTTP_CODE_OK
            ) {
              if (data?.lottery_url === null) {
                this.$store.dispatch('setSerialCodeViaSMS', serialCode);

                return this.$router.push({
                  name: 'campaign.auth.sms',
                  params: {
                    campaignUrlName: this.campaignUrlName,
                    campaignUrl: this.campaignUrl,
                  },
                  replace: true,
                }).catch(() => {});
              }

              const lotteryUuid = data.lottery_url.substring(
                data.lottery_url.lastIndexOf('/') + 1
              );

              // Check survey tool is Survey monkey or google forms
              if (data?.survey_tool) {
                switch (data.survey_tool) {
                  case SURVEY_TOOL_SURVEY_MONKEY: { // Survey monkey
                    // Redirect to survey page
                    if (data?.survey_link) {
                      // Start page loading
                      this.$store.dispatch('startPageRedirectLoading');
            
                      // Redirect to survey link
                      return window.location.href = data.survey_link;
                    }

                    break;
                  }
                  case SURVEY_TOOL_GOOGLE_FORM: { // Google form
                    // Check is step survey will redirect to survey page
                    if (data?.survey_link && data?.survey_key) {
                      // Start page loading
                      this.$store.dispatch('startPageRedirectLoading');

                      // Redirect to survey page
                      return this.$router.push({
                        name: 'campaign.survey',
                        params: {
                          survey_link: data.survey_link,
                          survey_key: data.survey_key
                        },
                        replace: true
                      }).catch(() => {});
                    }

                    break;
                  }
                }
              }

              // Push to lottery
              this.$router
                .push({
                  name: 'lottery.game',
                  params: {
                    campaignUrlName: this.campaignUrlName,
                    lotteryUuid,
                    isAccessUniqueUrl: this.isAccessUniqueUrl
                  },
                  replace: true,
                })
                .catch(() => {});
            } else {
              // Stop page loading
              this.$store.dispatch('stopPageRedirectLoading');

              // Handle for campaign is expired
              if (data.status === API_STATUS_CODE_IS_EXPIRED) {
                this.$router.push(
                  this.$helpers.redirectWithUtmQuery(
                    'campaign.home',
                    {},
                    {
                      campaignUrlName: data.data.campaign_url_name,
                      campaignUrl: data.data.campaign_url,
                    },
                    true,
                  )).catch(() => {});
              } else if (data.status === API_STATUS_CODE_RATE_LIMIT_EXCEED) {
                (this.serialCodeInvalid = true),
                  (this.messageErrors = this.$t('ERROR_PAGE.VERIFY_SERIAL_EXCEEDS_LIMIT'));
              } else {
                (this.serialCodeInvalid = true),
                  (this.messageErrors = this.$t('SERIAL_CODE_PAGE.ERROR_MSG.INVALID_SERIAL_CODE'));
              }
            }
          });
        this.isProcessing = false;
      }
    },
    // Check skip agreement for page
    isSkipAgreementFlg() {
      // Skip agreement if campaign is Redemption
      if (this.campaignInfo?.is_redemption_campaign) {
        return true;
      }

      // Check skip agreement if HAVE top agreement and top is skip agreement
      if (this.topContentSetting) {
        return true;
      }

      // Check skip agreement if NOT have top agreement and entry is skip agreement
      if (!this.topContentSetting &&
        this.entryContentSetting?.skip_agreement_flg == IS_SKIP_AGREEMENT_FLG
      ) {
        return true;
      }

      return false;
    }
  },
  created() {
    // Set app container style justify content center when not have main visual content
    if (!this.fullMainVisualUrl()) {
      this.$helpers.setRootElementStyle('--app-justify-content', 'center');
    }

    // Check if access by unique URL
    if (this.$route.query?.code) {
      const queryCode = this.$route.query.code;
      // Set input Serial code
      this.inputSerialCode = SERIAL_CODE_DEFAULT;

      if (queryCode) {
        this.isAccessUniqueUrl = true;
        this.inputSerialCode = queryCode;
      }

      // Call handle join campaign
      this.handleJoinCampaign();
    }
  }
};
