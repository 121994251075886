import {
  SERIAL_CODE_DEFAULT,
  ID_COUNTRY_CODE
} from '@constants';
import { mapState } from 'vuex';

export default {
  name: 'EntrySerialCode',
  props: {
    serialCodeInvalid: Boolean,
    messageErrors: String,
  },
  data() {
    return {
      inputSerialCode: '',
      maskSerialCode: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    }
  },
  watch: {
    // [Emit] set input serial code value
    'inputSerialCode' (value) {
      // Set input serial code
      this.$emit('set-input-serial-code', value);
    }
  },
  created() {
    // Set input serial code when query have
    this.inputSerialCode = this.$route?.query?.code !== SERIAL_CODE_DEFAULT ? this.$route.query.code : '';
  },
  computed: {
    ...mapState({
      country: 'country',
      entryContentSetting: 'entryContentSetting',
    }),
    getPlaceholderSerialCode() {
      let placeholder = this.$t('SERIAL_CODE_PAGE.PLACEHOLDER_ENTER_YOUR_SERIAL_CODE');

      if (this.country.country_code == ID_COUNTRY_CODE) {
        placeholder = this.entryContentSetting?.main_text ?? 'Masukkan kode serial Anda';
      }

      return placeholder;
    }
  }
}
