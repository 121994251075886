import {
  ID_COUNTRY_CODE
} from '@constants';
import { mapState } from 'vuex';

export default {
  name: 'EntryPhoneNumber',
  props: {
    phoneNumberInvalid: Boolean,
    messageErrors: String,
    disableInputPhoneNumber: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputPhoneNumber: '',
      maskPhoneNumber: '000000000000000',
    };
  },
  watch: {
    // [Emit] set input phone number value
    inputPhoneNumber(value) {
      // Set input phone number
      this.$emit('set-input-phone-number', value);
    },
  },
  computed: {
    ...mapState({
      country: 'country',
      entryContentSetting: 'entryContentSetting',
    }),
    getPlaceholderSerialCode() {
      let placeholder = this.$t('PHONE_NUMBER_PAGE.PLACEHOLDER_ENTER_PHONE_NUMBER');

      if (this.country.country_code == ID_COUNTRY_CODE) {
        placeholder = this.entryContentSetting?.main_text ?? 'Masukkan nomor telepon Anda';
      }

      return placeholder;
    }
  }
};
